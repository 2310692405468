export const isUserManager = (id: string) => {
  return (
    // 운전선생
    id === 'kakao:2467505415' ||
    // 석준
    id === 'kakao:2513556388' ||
    // 희준
    id === 'kakao:2471723267' ||
    // 인아
    id === 'kakao:2543888494' ||
    // 태웅
    id === 'kakao:2469701592' ||
    // 재만
    id === 'kakao:2514391593' ||
    // 재현
    id === 'kakao:2721632501' ||
    // 형근
    id === 'kakao:2815218735' ||
    // 새미
    id === 'kakao:2905921193' ||
    // 라희
    id === 'kakao:3057360395' ||
    // 형겸
    id === 'kakao:3214469656' ||
    // info-test
    id === 'qm4eGhAJsmgOUKbH4hOFTSvJcXf1' ||
    // 세현
    id === 'kakao:3015587507' ||
    // 재찬
    id === 'kakao:3674466908'
  );
};
