import axios from 'axios';

export const sendKakaoMessage = async (messageData: object) => {
  try {
    await axios({
      url: 'https://msggw.supersms.co:9443/v1/send/kko',
      method: 'POST',
      headers: {
        'X-IB-Client-Id': 'tgsociety_alim',
        'X-IB-Client-Passwd': 'cZ0NQQ2CgFKdylQS2Wwe',
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
      data: messageData,
    });
    console.log(new Date(), '카카오 알림톡 실행 성공', `${JSON.stringify(messageData)}`);
  } catch (error) {
    console.error(new Date(), '', '카카오 알림톡 실행 실패', `${error}`);
  }
};
