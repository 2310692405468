import { useEffect, useState } from 'react';

import { LogEvent } from '@analytics';

// AB 테스트 값 목록을 정의
const AB_TEST_KEY = {
  LOGIN_NAVIGATE: ['YES', 'NO'],
  GUIDE_NEED_LOGIN: ['YES', 'NO'],
} as const;

// AB 테스트 키 유형
type ABTestKey = keyof typeof AB_TEST_KEY;

// AB 테스트 값 유형 유추
type ABTestValue<T extends ABTestKey> = (typeof AB_TEST_KEY)[T][number];

// Hook 외부에서 정의된 getInitialValue 함수
function getInitialValue<T extends ABTestKey>(abTestKey: T): ABTestValue<T> {
  const storedValue = localStorage.getItem(abTestKey);
  if (storedValue !== null) {
    return JSON.parse(storedValue);
  } else {
    const values = AB_TEST_KEY[abTestKey];
    const randomIndex = Math.floor(Math.random() * values.length);
    const randomValue = values[randomIndex];
    localStorage.setItem(abTestKey, JSON.stringify(randomValue));
    return randomValue;
  }
}

// AB 테스트 설정을 관리하는 Hook
// https://www.notion.so/useABTest-5bfe823b270f4d6583421c6a4019f435?pvs=4
export const useABTest = <T extends ABTestKey>(
  abTestKey: T,
): [ABTestValue<T>, (value: ABTestValue<T>) => void] => {
  const [value, setValue] = useState<ABTestValue<T>>(AB_TEST_KEY[abTestKey][0]);

  useEffect(() => {
    // 서버의 값은 무시하고 client에서 재생성함.
    if (typeof window === 'undefined') return;

    const initialValue = getInitialValue(abTestKey);
    setValue(initialValue);
  }, []);

  // 값이 변경될 때 localStorage를 업데이트
  useEffect(() => {
    if (typeof window === 'undefined') return;
    localStorage.setItem(abTestKey, JSON.stringify(value));

    LogEvent.유지.setUserProperty({ [`abtest_${abTestKey}`]: value });
  }, [value, abTestKey]);

  return [value, setValue];
};
