import { setURLState } from '../utils';

const getUrlSearch = () => {
  return window.location.search.slice(1);
};

/**
 * 리렌더링을 발생하지 않는 방식으로 현재 URL의 쿼리스트링을 변경합니다.
 * 기존의 쿼리스트링은 유지하고, 새로운 쿼리스트링을 추가하거나 변경합니다.
 */
export const replaceCurrentURLState = (
  newState: Record<string, string>[] | Record<string, string>,
) => {
  const currentURL = getUrlSearch();

  const newSearchParams = setURLState(
    currentURL,
    Array.isArray(newState) ? newState : [newState],
    'set',
  );

  const newURL = window.location.pathname + `?${newSearchParams}`;

  // 데이터가 변경될 때마다 url을 변경
  window.history.replaceState({ ...window.history.state, as: newURL, url: newURL }, '', newURL);
};

export const deleteCurrentURLState = (keys: string[]) => {
  const currentURL = getUrlSearch();

  const newSearchParams = setURLState(
    currentURL,
    keys.map((key) => ({ [key]: '' })),
    'delete',
  );

  const newURL = window.location.pathname + `?${newSearchParams}`;

  // 데이터가 변경될 때마다 url을 변경
  window.history.replaceState({ ...window.history.state, as: newURL, url: newURL }, '', newURL);
};
