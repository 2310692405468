/**
 * children이 null인지 판단
 * https://stackoverflow.com/questions/55303409/react-component-children-detect-if-empty-null-before-render
 * @returns
 */
export const isChildNull = (children: any) => {
  if (!children || typeof children.type !== 'function') {
    return false;
  }

  const { type, props } = children;
  return Boolean(type(props) === null);
};
