import { replaceCurrentURLState } from 'shared-values';
import SuperJSON from 'superjson';
import { PersistOptions, StorageValue } from 'zustand/middleware';

const getUrlSearch = () => {
  return window.location.search.slice(1);
};

/**
 * persistKey에 속하는 key들을 url parameter에 저장하는 storage
 */
export const queryStringStorage = <T extends {}>(persistKey: string[]): PersistOptions<T, T> => ({
  // 현재 url에 저장할 것이기 때문에 key가 필요 없음
  name: '',
  storage: {
    getItem: () => {
      const searchParams = new URLSearchParams(getUrlSearch());

      const storedValue: Partial<T> = {};
      for (const [key, value] of searchParams.entries()) {
        if (!persistKey.includes(key)) continue;
        storedValue[key as keyof T] = SuperJSON.parse(value);
      }

      return { state: storedValue as T };
    },
    setItem: async (_, newValue: StorageValue<T>) => {
      if (!newValue) return;

      const newStates = Object.entries(newValue.state)
        .filter(([key]) => persistKey.includes(key))
        .map(([key, value]) => [key, SuperJSON.stringify(value)])
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {} as Record<string, string>);

      replaceCurrentURLState(newStates);
    },
    removeItem: (key) => {
      const searchParams = new URLSearchParams(getUrlSearch());
      searchParams.delete(key);
      const newUrl = window.location.pathname + `?${searchParams.toString()}`;

      window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl);
    },
  },
});
