import curriculum from './curriculum';

const { 학과, 장내기능, 도로주행, 운전연수 } = curriculum;

export default {
  TWO_NORMAL: {
    display: {
      main: '2종 보통',
      sub: '신규',
    },
    priority: 1,
    requiredCourseHour: 13,
    introduce: {
      description:
        '자동변속기 차량 운전이 가능한 면허예요. 10인승 이하 차량은 대부분 운전이 가능하며, 일반적인 상황이라면 가장 추천해 드려요.',
      images: [
        {
          src: '/lesson/illust-2종보통.png',
          alt: '2종보통',
        },
      ],
      vehicles: [
        {
          icon: 'car',
          label: '승용차',
          description: '10인 이하',
        },
        {
          icon: 'bike',
          label: '이륜차',
          description: '125cc 이하',
        },
        {
          icon: 'truck',
          label: '화물차',
          description: '적재중량 4t 이하',
        },
        {
          icon: 'tow-truck',
          label: '특수차',
          description: '3.5t 이하',
        },
      ],
    },
    curriculum: [
      학과({ hour: 3, score: 60, license: '2종 보통' }),
      장내기능({ hour: 4, score: 80, license: '2종 보통' }),
      도로주행({ hour: 6, score: 70, license: '2종 보통' }),
    ],
    requirements: ['만 18세 이상', '양안시력 0.5 이상'],
    materials: [
      {
        text: '신분증',
      },
      {
        text: '추가 비용 등의 결제를 위한 현금이나 카드',
      },
      {
        text: '(학과시험 합격자) 응시표',
      },
      {
        text: '(선택) 신체검사결과지',
        info: '강릉, 태백, 문경, 광양, 충주 면허시험장 내에는\n신체검사장이 없으므로, 가까운 병원에서\n신체 검사를 받으시길 바랍니다.',
      },
    ],
  },
  ONE_NORMAL: {
    display: {
      main: '1종 보통',
      sub: '신규',
    },
    priority: 2,
    requiredCourseHour: 13,
    introduce: {
      description:
        '경찰공무원 및 소방공무원 시험을 응시하려면 꼭 필요한 면허예요. 수동변속기 차량은 물론, 15인승 이하 차량은 대부분 운전이 가능해요. ',
      images: [
        {
          src: '/lesson/illust-1종보통.png',
          alt: '1종보통',
        },
      ],
      vehicles: [
        {
          icon: 'car',
          label: '승용차',
          description: '모든 승용차',
        },
        {
          icon: 'bike',
          label: '이륜차',
          description: '125cc 이하',
        },
        {
          icon: 'truck',
          label: '화물차',
          description: '적재중량 12t 미만',
        },
        {
          icon: 'tow-truck',
          label: '특수차',
          description: '10t 미만',
        },
        {
          icon: 'dump-truck',
          label: '건설기계',
          description: '',
        },
      ],
    },
    curriculum: [
      학과({ hour: 3, score: 70, license: '1종 보통' }),
      장내기능({ hour: 4, score: 80, license: '1종 보통' }),
      도로주행({ hour: 6, score: 70, license: '1종 보통' }),
    ],
    requirements: ['만 18세 이상', '양안시력 0.8 이상'],
    materials: [
      {
        text: '신분증',
      },
      {
        text: '추가 비용 등의 결제를 위한 현금이나 카드',
      },
      {
        text: '(학과시험 합격자) 응시표',
      },
      {
        text: '(선택) 신체검사결과지',
        info: '강릉, 태백, 문경, 광양, 충주 면허시험장 내에는\n신체검사장이 없으므로, 가까운 병원에서\n신체 검사를 받으시길 바랍니다.',
      },
    ],
  },
  TRAINING_10_HOURS: {
    display: {
      main: '운전 연수',
      sub: '10시간',
    },
    priority: 6,
    requiredCourseHour: 10,
    introduce: {
      description: '면허를 취득하셨더라도, 운전학원에서 안전하게 연수받으실 수 있어요.',
      images: [
        {
          src: '/lesson/illust-2종보통.png',
          alt: '운전연수',
        },
      ],
      vehicles: [],
    },
    curriculum: [운전연수({ hour: 10 })],
    requirements: [],
    materials: [
      {
        text: '운전면허증',
      },
      {
        text: '추가 비용 등의 결제를 위한 현금이나 카드',
      },
    ],
  },
  TRAINING_6_HOURS: {
    display: {
      main: '운전 연수',
      sub: '6시간',
    },
    priority: 5,
    requiredCourseHour: 6,
    introduce: {
      description: '면허를 취득하셨더라도, 운전학원에서 안전하게 연수받으실 수 있어요.',
      images: [
        {
          src: '/lesson/illust-2종보통.png',
          alt: '운전연수',
        },
      ],
      vehicles: [],
    },
    curriculum: [운전연수({ hour: 6 })],
    requirements: [],
    materials: [
      {
        text: '운전면허증',
      },
      {
        text: '추가 비용 등의 결제를 위한 현금이나 카드',
      },
    ],
  },
  TRAINING_4_HOURS: {
    display: {
      main: '운전 연수',
      sub: '4시간',
    },
    priority: 4,
    requiredCourseHour: 4,
    introduce: {
      description: '면허를 취득하셨더라도, 운전학원에서 안전하게 연수받으실 수 있어요.',
      images: [
        {
          src: '/lesson/illust-2종보통.png',
          alt: '운전연수',
        },
      ],
      vehicles: [],
    },
    curriculum: [운전연수({ hour: 4 })],
    requirements: [],
    materials: [
      {
        text: '운전면허증',
      },
      {
        text: '추가 비용 등의 결제를 위한 현금이나 카드',
      },
    ],
  },
  ONE_LARGE: {
    display: {
      main: '1종 대형',
      sub: null,
    },
    priority: 3,
    requiredCourseHour: 13,
    introduce: {
      description:
        '1종/2종 보통 면허 취득 후, 1년이 지나면 도전하실 수 있어요.\n16인승 이상의 대형 차량 운전이 가능하며, 취득 시 공무원 시험 가산점 혜택이 있어요.',
      images: [
        {
          src: '/lesson/illust-1종대형.png',
          alt: '1종대형',
        },
      ],
      vehicles: [
        {
          icon: 'car',
          label: '승용차',
          description: '모든 승용차',
        },
        {
          icon: 'bike',
          label: '이륜차',
          description: '125cc 이하',
        },
        {
          icon: 'truck',
          label: '화물차',
          description: '모든 화물차',
        },
        {
          icon: 'tow-truck',
          label: '특수차',
          description: '견인차 및 구난차 제외',
        },
        {
          icon: 'dump-truck',
          label: '건설기계',
          description: '',
        },
      ],
    },
    curriculum: [
      학과({
        hour: 3,
        score: 0,
        license: '1종 대형',
      }),
      장내기능({
        hour: 10,
        score: 80,
        license: '1종 대형',
      }),
    ],
    requirements: ['만 19세 이상', '양안시력 0.8 이상', '1/2종 보통 면허 취득 후 1년 이상'],
    materials: [
      {
        text: '신분증',
      },
      {
        text: '추가 비용 등의 결제를 위한 현금이나 카드',
      },
      {
        text: '컬러 증명사진 3장',
        children: [
          {
            text: '6개월 이내 촬영',
          },
          {
            text: '규격 3.5cm x 4.5cm',
          },
        ],
      },
      {
        text: '(선택) 신체검사결과지',
        info: '강릉, 태백, 문경, 광양, 충주 면허시험장 내에는\n신체검사장이 없으므로, 가까운 병원에서\n신체 검사를 받으시길 바랍니다.',
      },
      {
        text: '운전경력증명서',
      },
    ],
  },
  TRANSFORM_TWO_TO_ONE: {
    display: {
      main: '종별 전환',
      sub: '',
    },
    priority: 7,
    requiredCourseHour: 6,
    introduce: {
      description:
        '2종 보통 면허를 취득했더라도, 수동변속기 차량이나 11인승 이상 15인승 이하 차량 등을 운전해야 할 때는 1종 보통으로 전환해야 해요. 도로주행시험만 응시하면 종별 전환이 가능해요.',
      images: [
        {
          src: '/lesson/illust-종별전환.png',
          alt: '종별전환',
        },
      ],
      vehicles: [
        {
          icon: 'car',
          label: '승용차',
          description: '모든 승용차',
        },
        {
          icon: 'bike',
          label: '이륜차',
          description: '125cc 이하',
        },
        {
          icon: 'truck',
          label: '화물차',
          description: '적재중량 12t 미만',
        },
        {
          icon: 'tow-truck',
          label: '특수차',
          description: '10t 미만',
        },
        {
          icon: 'dump-truck',
          label: '건설기계',
          description: '',
        },
      ],
    },
    curriculum: [도로주행({ hour: 6, score: 70, license: '종별 전환' })],
    requirements: ['만 18세 이상', '양안시력 0.8 이상', '2종 보통 면허 보유자'],
    materials: [
      {
        text: '면허증',
      },
      {
        text: '추가 비용 등의 결제를 위한 현금이나 카드',
      },
      {
        text: '연습면허 응시표',
      },
      {
        text: '(선택) 신체검사결과지',
        info: '강릉, 태백, 문경, 광양, 충주 면허시험장 내에는\n신체검사장이 없으므로, 가까운 병원에서\n신체 검사를 받으시길 바랍니다.',
      },
    ],
  },
  TWO_SMALL_NEW: {
    display: {
      main: '2종 소형',
      sub: '신규',
    },
    priority: 8,
    requiredCourseHour: 15,
    introduce: {
      description:
        '다른 면허를 가지고 있더라도, 125cc 초과 이륜차를 운전하시려면 2종 소형을 반드시 취득해야 해요. 2종 소형으로 모든 이륜차 운전이 가능해요.',
      images: [
        {
          src: '/lesson/illust-2종소형.png',
          alt: '종별전환',
        },
      ],
      vehicles: [
        {
          icon: 'bike',
          label: '이륜차',
          description: '모든 이륜차',
        },
      ],
    },
    curriculum: [
      학과({ hour: 5, score: 60, license: '2종 소형' }),
      장내기능({ hour: 10, score: 90, license: '2종 소형' }),
    ],
    requirements: ['만 18세 이상', '양안시력 0.5 이상'],
    materials: [
      {
        text: '신분증',
      },
      {
        text: '추가 비용 등의 결제를 위한 현금이나 카드',
      },
      {
        text: '컬러 증명사진 3장',
        children: [
          {
            text: '6개월 이내 촬영',
          },
          {
            text: '규격 3.5cm x 4.5cm',
          },
        ],
      },
      {
        text: '(선택) 신체검사결과지',
        info: '강릉, 태백, 문경, 광양, 충주 면허시험장 내에는\n신체검사장이 없으므로, 가까운 병원에서\n신체 검사를 받으시길 바랍니다.',
      },
    ],
  },
  TWO_SMALL_HAVE_LICENSE: {
    display: {
      main: '2종 소형',
      sub: '1/2종 보유자',
    },
    priority: 9,
    requiredCourseHour: 13,
    introduce: {
      description:
        '다른 면허를 가지고 있더라도, 125cc 초과 이륜차를 운전하시려면 2종 소형을 반드시 취득해야 해요. 1/2종 면허를 취득하셨다면, 학과교육이 3시간으로 줄어들어요.',
      images: [
        {
          src: '/lesson/illust-2종소형.png',
          alt: '2종소형',
        },
      ],
      vehicles: [
        {
          icon: 'bike',
          label: '이륜차',
          description: '모든 이륜차',
        },
      ],
    },
    curriculum: [
      학과({ hour: 3, score: 0, license: '1/2종 면허 보유자' }),
      장내기능({ hour: 10, score: 90, license: '2종 소형' }),
    ],
    requirements: ['만 18세 이상', '양안시력 0.5 이상', '1/2종 보통 면허 보유자'],
    materials: [
      {
        text: '신분증',
      },
      {
        text: '추가 비용 등의 결제를 위한 현금이나 카드',
      },
      {
        text: '컬러 증명사진 3장',
        children: [
          {
            text: '6개월 이내 촬영',
          },
          {
            text: '규격 3.5cm x 4.5cm',
          },
        ],
      },
      {
        text: '(선택) 신체검사결과지',
        info: '강릉, 태백, 문경, 광양, 충주 면허시험장 내에는\n신체검사장이 없으므로, 가까운 병원에서\n신체 검사를 받으시길 바랍니다.',
      },
    ],
  },
  TWO_SMALL_HAVE_MOTOR: {
    display: {
      main: '2종 소형',
      sub: '원동기 보유자',
    },
    priority: 10,
    requiredCourseHour: 6,
    introduce: {
      description:
        '다른 면허를 가지고 있더라도, 125cc 초과 이륜차를 운전하시려면 2종 소형을 반드시 취득해야 해요. 원동기 면허를 취득하셨다면, 학과교육과 시험이 모두 면제돼요.',
      images: [
        {
          src: '/lesson/illust-2종소형.png',
          alt: '2종소형',
        },
      ],
      vehicles: [
        {
          icon: 'bike',
          label: '이륜차',
          description: '모든 이륜차',
        },
      ],
    },
    curriculum: [장내기능({ hour: 6, score: 90, license: '원동기 면허 보유자' })],
    requirements: ['만 18세 이상', '양안시력 0.5 이상', '원동기 면허 보유자'],
    materials: [
      {
        text: '신분증',
      },
      {
        text: '추가 비용 등의 결제를 위한 현금이나 카드',
      },
      {
        text: '컬러 증명사진 3장',
        children: [
          {
            text: '6개월 이내 촬영',
          },
          {
            text: '규격 3.5cm x 4.5cm',
          },
        ],
      },
      {
        text: '(선택) 신체검사결과지',
        info: '강릉, 태백, 문경, 광양, 충주 면허시험장 내에는\n신체검사장이 없으므로, 가까운 병원에서\n신체 검사를 받으시길 바랍니다.',
      },
    ],
  },
  ONE_CANCEL: {
    display: {
      main: '1종 경력자',
      sub: '경력자',
    },
    priority: 11,
    requiredCourseHour: null,
    introduce: {
      description: '',
      images: [],
      vehicles: [],
    },
    curriculum: [],
    requirements: [
      '운전면허 결격 기간이 지나야 합니다.',
      '특별 교통안전교육을 수료 완료해야 합니다.',
    ],
    materials: [
      {
        text: '신분증',
      },
      {
        text: '추가 비용 등의 결제를 위한 현금이나 카드',
      },
      {
        text: '컬러 증명사진 3장',
        children: [
          {
            text: '6개월 이내 촬영',
          },
          {
            text: '규격 3.5cm x 4.5cm',
          },
        ],
      },
      {
        text: '(선택) 신체검사결과지',
        info: '강릉, 태백, 문경, 광양, 충주 면허시험장 내에는\n신체검사장이 없으므로, 가까운 병원에서\n신체 검사를 받으시길 바랍니다.',
      },
      {
        text: '운전경력증명서',
      },
    ],
  },
  TWO_CANCEL: {
    display: {
      main: '2종 경력자',
      sub: '경력자',
    },
    priority: 12,
    requiredCourseHour: null,
    introduce: {
      description: '',
      images: [],
      vehicles: [],
    },
    curriculum: [],
    requirements: [
      '운전면허 결격 기간이 지나야 합니다.',
      '특별 교통안전교육을 수료 완료해야 합니다.',
    ],
    materials: [
      {
        text: '신분증',
      },
      {
        text: '추가 비용 등의 결제를 위한 현금이나 카드',
      },
      {
        text: '컬러 증명사진 3장',
        children: [
          {
            text: '6개월 이내 촬영',
          },
          {
            text: '규격 3.5cm x 4.5cm',
          },
        ],
      },
      {
        text: '(선택) 신체검사결과지',
        info: '강릉, 태백, 문경, 광양, 충주 면허시험장 내에는\n신체검사장이 없으므로, 가까운 병원에서\n신체 검사를 받으시길 바랍니다.',
      },
      {
        text: '운전경력증명서',
      },
    ],
  },
  ONE_NORMAL_ROAD: {
    display: {
      main: '1종 보통',
      sub: '도로주행',
    },
    priority: 11,
    requiredCourseHour: null,
    introduce: {
      description: '',
      images: [],
      vehicles: [],
    },
    curriculum: [],
    requirements: [
      '운전면허 결격 기간이 지나야 합니다.',
      '특별 교통안전교육을 수료 완료해야 합니다.',
    ],
    materials: [
      {
        text: '신분증',
      },
      {
        text: '추가 비용 등의 결제를 위한 현금이나 카드',
      },
      {
        text: '컬러 증명사진 3장',
        children: [
          {
            text: '6개월 이내 촬영',
          },
          {
            text: '규격 3.5cm x 4.5cm',
          },
        ],
      },
      {
        text: '(선택) 신체검사결과지',
        info: '강릉, 태백, 문경, 광양, 충주 면허시험장 내에는\n신체검사장이 없으므로, 가까운 병원에서\n신체 검사를 받으시길 바랍니다.',
      },
      {
        text: '운전경력증명서',
      },
    ],
  },
  TWO_NORMAL_ROAD: {
    display: {
      main: '2종 보통',
      sub: '도로주행',
    },
    priority: 12,
    requiredCourseHour: null,
    introduce: {
      description: '',
      images: [],
      vehicles: [],
    },
    curriculum: [],
    requirements: [
      '운전면허 결격 기간이 지나야 합니다.',
      '특별 교통안전교육을 수료 완료해야 합니다.',
    ],
    materials: [
      {
        text: '신분증',
      },
      {
        text: '추가 비용 등의 결제를 위한 현금이나 카드',
      },
      {
        text: '컬러 증명사진 3장',
        children: [
          {
            text: '6개월 이내 촬영',
          },
          {
            text: '규격 3.5cm x 4.5cm',
          },
        ],
      },
      {
        text: '(선택) 신체검사결과지',
        info: '강릉, 태백, 문경, 광양, 충주 면허시험장 내에는\n신체검사장이 없으므로, 가까운 병원에서\n신체 검사를 받으시길 바랍니다.',
      },
      {
        text: '운전경력증명서',
      },
    ],
  },
  SCOOTER: {
    display: {
      main: '원동기',
      sub: null,
    },
    priority: 13,
    requiredCourseHour: null,
    introduce: {
      description: '',
      images: [],
      vehicles: [],
    },
    curriculum: [],
    requirements: ['만 16세 이상'],
    materials: [
      {
        text: '신분증',
      },
      {
        text: '추가 비용 등의 결제를 위한 현금이나 카드',
      },
      {
        text: '컬러 증명사진 3장',
        children: [
          {
            text: '6개월 이내 촬영',
          },
          {
            text: '규격 3.5cm x 4.5cm',
          },
        ],
      },
      {
        text: '(선택) 신체검사결과지',
        info: '강릉, 태백, 문경, 광양, 충주 면허시험장 내에는\n신체검사장이 없으므로, 가까운 병원에서\n신체 검사를 받으시길 바랍니다.',
      },
    ],
  },
  SMALL_TRAILER: {
    display: {
      main: '소형 견인',
      sub: null,
    },
    priority: 14,
    requiredCourseHour: null,
    introduce: {
      description: '',
      images: [],
      vehicles: [],
    },
    curriculum: [],
    requirements: ['1, 2종 보통면허 1년 이상 보유자'],
    materials: [
      {
        text: '신분증',
      },
      {
        text: '추가 비용 등의 결제를 위한 현금이나 카드',
      },
      {
        text: '컬러 증명사진 3장',
        children: [
          {
            text: '6개월 이내 촬영',
          },
          {
            text: '규격 3.5cm x 4.5cm',
          },
        ],
      },
      {
        text: '(선택) 신체검사결과지',
        info: '강릉, 태백, 문경, 광양, 충주 면허시험장 내에는\n신체검사장이 없으므로, 가까운 병원에서\n신체 검사를 받으시길 바랍니다.',
      },
    ],
  },
  LARGE_TRAILER: {
    display: {
      main: '대형 견인',
      sub: null,
    },
    priority: 15,
    requiredCourseHour: null,
    introduce: {
      description: '',
      images: [],
      vehicles: [],
    },
    curriculum: [],
    requirements: ['1, 2종 보통면허 1년 이상 보유자'],
    materials: [
      {
        text: '신분증',
      },
      {
        text: '추가 비용 등의 결제를 위한 현금이나 카드',
      },
      {
        text: '컬러 증명사진 3장',
        children: [
          {
            text: '6개월 이내 촬영',
          },
          {
            text: '규격 3.5cm x 4.5cm',
          },
        ],
      },
      {
        text: '(선택) 신체검사결과지',
        info: '강릉, 태백, 문경, 광양, 충주 면허시험장 내에는\n신체검사장이 없으므로, 가까운 병원에서\n신체 검사를 받으시길 바랍니다.',
      },
    ],
  },
  TRACTION: {
    display: {
      main: '구난차',
      sub: null,
    },
    priority: 16,
    requiredCourseHour: null,
    introduce: {
      description: '',
      images: [],
      vehicles: [],
    },
    curriculum: [],
    requirements: ['1, 2종 보통면허 1년 이상 보유자'],
    materials: [
      {
        text: '신분증',
      },
      {
        text: '추가 비용 등의 결제를 위한 현금이나 카드',
      },
      {
        text: '컬러 증명사진 3장',
        children: [
          {
            text: '6개월 이내 촬영',
          },
          {
            text: '규격 3.5cm x 4.5cm',
          },
        ],
      },
      {
        text: '(선택) 신체검사결과지',
        info: '강릉, 태백, 문경, 광양, 충주 면허시험장 내에는\n신체검사장이 없으므로, 가까운 병원에서\n신체 검사를 받으시길 바랍니다.',
      },
      {
        text: '운전경력증명서',
      },
    ],
  },
};
