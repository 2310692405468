import { useCallback, useEffect, useState } from 'react';

import { TOP_NAV_BAR_HEIGHT } from '@constants';

import { BOTTOM_NAV_BAR_HEIGHT } from './../constants/style';

interface WindowSizeProps {
  isStatusHeight?: boolean;
  isTopNavbarHeight?: boolean;
  isBottomNavbarHeight?: boolean;
}

/**
 *
 * @param param0 isTopNavbarHeight: 상단 네비게이션 높이를 제외할지 여부, 기본 true
 * @param param0 isStatusHeight: 상태바 높이를 제외할지 여부, 기본 false
 * @param param0 isBottomNavbarHeight: 하단 네비게이션 높이를 제외할지 여부, 기본 false
 * @returns
 */
export const useWindowSize = (
  {
    isTopNavbarHeight = true,
    isStatusHeight = false,
    isBottomNavbarHeight = false,
  }: WindowSizeProps = {
    isTopNavbarHeight: true,
    isStatusHeight: true,
    isBottomNavbarHeight: false,
  },
) => {
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });

  // eslint-disable-next-line no-inner-declarations
  const handleResize = useCallback(() => {
    const statusBarHeight = Number(window.statusBarHeight ?? 0);

    setWindowSize({
      width: window.innerWidth,
      height:
        window.innerHeight -
        (isStatusHeight ? statusBarHeight : 0) -
        (isTopNavbarHeight ? TOP_NAV_BAR_HEIGHT : 0) -
        (isBottomNavbarHeight ? BOTTOM_NAV_BAR_HEIGHT : 0),
    });
  }, [isStatusHeight, isTopNavbarHeight, isBottomNavbarHeight]);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    window.addEventListener('resize', handleResize);
    window.addEventListener('triggerResize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('triggerResize', handleResize);
    };
  }, [isStatusHeight, isTopNavbarHeight, isBottomNavbarHeight]);

  const triggerResize = () => {
    handleResize();
  };

  return { ...windowSize, triggerResize };
};
