import { TogetherEventTable } from '@db/base-models';

import { QUERY_KEY } from '../../../constants';
import { QueryModel } from '../../../query-models';
import { getDataWithReactQuery } from '../../../utils';
import { getAcademyGeoJson } from '../../AcademyGeoJson';
import { Review } from '../../Review';
import { AcademyBlog } from './academy-blog';
import { AcademyLocation } from './academy-location';
import { AcademyReview } from './academy-review';
import { BaseAcademy } from './base-academy';
import { DetailInfo } from './detail-info';
import { Lesson, SimulationLesson } from './lesson';
import { RequestGoods } from './request-goods';
import { RequestInfo } from './request-info';
import { SmallmallInfo } from './smallmall-info';
import { SubmallInfo } from './submall-info';

export class Academy extends QueryModel {
  id: string;
  location: AcademyLocation;
  name: string;
  linebreakName: string;
  thumbnailImage?: string;
  images: string[];
  lessons: (Lesson | SimulationLesson)[];
  phoneNum?: string;
  safetyPhoneNum?: string;
  marker?: any;
  distance = 0;
  shuttleSchedule: string[];
  website?: string;
  detailInfo?: DetailInfo;
  type: AcademyType;
  isBookable: boolean;
  requestPhoneNum?: string[];
  review: AcademyReview;
  requestInfo?: RequestInfo;
  requestGoods?: RequestGoods[];
  properties: AcademyProperty[];
  additionalProperties: string[];
  submallInfo?: SubmallInfo;
  smallmallInfo?: SmallmallInfo;
  academyBlogs: AcademyBlog[];
  youtubeLink?: string;
  state: 입점상태;
  paymentType: PaymentType;
  registCorp: boolean;
  isVerified: boolean;
  isVisible: boolean;
  togetherEventTable?: TogetherEventTable;
  operationTime: string;
  topReviews: Review[];
  totalReviewCount: number;
  averageRate: {
    facility: number;
    lecture: number;
    service: number;
    totalAverage: number;
  };
  canUseFirstComeCoupon: boolean;
  canReceiveDiscount: boolean;
  inquiryCount: number;
  instructors: AcademyData['instructors'];

  private baseAcademy: BaseAcademy;

  constructor({
    id,
    location,
    name,
    linebreakName,
    thumbnailImage,
    images,
    lessons,
    phoneNum,
    safetyPhoneNum,
    marker,
    shuttleSchedule,
    website,
    detailInfo,
    type,
    isBookable,
    requestPhoneNum,
    review,
    requestInfo,
    requestGoods,
    properties,
    additionalProperties,
    submallInfo,
    smallmallInfo,
    academyBlogs,
    youtubeLink,
    queryClient,
    queryKey,
    instanceConstructor,
    className,
    state,
    paymentType,
    registCorp,
    isVerified,
    isVisible,
    togetherEventTable,
    operationTime,
    topReviews,
    totalReviewCount,
    averageRate,
    canUseFirstComeCoupon,
    canReceiveDiscount,
    inquiryCount,
    instructors,
  }: DataModel<AcademyData> & Partial<QueryModelInnerProps>) {
    super({
      queryClient,
      queryKey,
      instanceConstructor: instanceConstructor ?? Academy,
      className: className ?? 'Academy',
    });
    // 다중 상속 문제로 합성으로 일단 해결
    this.baseAcademy = new BaseAcademy({ id });

    const isSimulation = type === 'simulation';

    this.id = id;
    this.location = new AcademyLocation(location);
    this.name = name;
    this.linebreakName = linebreakName ? linebreakName.replaceAll('\\n', '\n') : '';
    this.thumbnailImage = thumbnailImage;
    this.images = images;
    this.lessons = lessons.map((lesson) =>
      isSimulation
        ? new SimulationLesson(lesson as SimulationLessonData)
        : new Lesson(lesson as LessonData),
    );
    this.phoneNum = phoneNum;
    this.safetyPhoneNum = safetyPhoneNum;
    this.marker = marker;
    this.shuttleSchedule = shuttleSchedule;
    this.website = website;
    this.detailInfo = detailInfo ? new DetailInfo(detailInfo) : undefined;
    this.type = type;
    this.isBookable = isBookable;
    this.requestPhoneNum = requestPhoneNum;
    this.review = new AcademyReview(review);
    this.requestInfo = requestInfo;
    this.requestGoods = requestGoods;
    this.properties = properties;
    this.additionalProperties = additionalProperties;
    this.submallInfo = submallInfo ? new SubmallInfo(submallInfo) : undefined;
    this.smallmallInfo = smallmallInfo ? new SmallmallInfo(smallmallInfo) : undefined;
    this.academyBlogs = academyBlogs ? academyBlogs.map((blog) => new AcademyBlog(blog)) : [];
    this.youtubeLink = youtubeLink;
    this.state = state;
    this.paymentType = paymentType;
    this.registCorp = registCorp;
    this.isVerified = isVerified;
    this.isVisible = isVisible;
    this.togetherEventTable = togetherEventTable
      ? new TogetherEventTable(togetherEventTable)
      : undefined;
    this.topReviews = topReviews ? topReviews.map((review) => new Review(review)) : [];
    this.totalReviewCount = totalReviewCount ?? 0;
    this.averageRate = averageRate ?? { facility: 0, lecture: 0, service: 0, totalAverage: 0 };

    this.operationTime = operationTime ?? '';
    this.canUseFirstComeCoupon = canUseFirstComeCoupon ?? false;
    this.canReceiveDiscount =
      canReceiveDiscount || this.lessons.some((lesson) => lesson.discountPrice > 0);
    this.inquiryCount = inquiryCount ?? 0;
    this.instructors = instructors ?? [];
  }

  get isAcademy() {
    return this.type === 'general-academy' || this.type === 'special-academy';
  }

  get isGeneral() {
    return this.type === 'general-academy';
  }

  get isSimulation() {
    return this.type === 'simulation';
  }

  get is경기남부() {
    return false;
  }

  get displayName() {
    if (this.type === 'simulation') {
      return this.name;
    }
    return `${this.name} ${this.isGeneral ? '자동차운전학원' : '자동차운전전문학원'}`;
  }

  get locationAttachedName() {
    const 도 = this.location.bubAddress.split(' ')[0];
    return `${도}_${this.name}`;
  }

  get isAcademyBookable() {
    return this.state === '입점';
  }

  get isMarkerActivated() {
    return this.state === '입점' || this.state === '가입점' || this.state === '가라입점';
  }

  /**
   * base Academy로부터 작성
   */
  get isTestAcademy() {
    // 일단 live에서는 시뮬레이션 안보이도록 설정
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.baseAcademy.isTestAcademy;
  }
  get claimableInfo() {
    return this.baseAcademy.claimableInfo;
  }
  get hyphenPhoneNum() {
    if (this.safetyPhoneNum) {
      return this.safetyPhoneNum
        .replace(/[^0-9]/g, '')
        .replace(/(^02|^0505|^0507|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, '$1-$2-$3')
        .replace('--', '-');
    } else {
      return '-';
    }
  }

  public setDisatnce(distance: number) {
    this.distance = distance;
  }

  public isLessonExist(code: LessonCode) {
    return this.lessons.some((v) => v.code === code);
  }

  public selectLesson({
    code,
    periodText,
    registType,
  }: {
    code: LessonCode | '';
    periodText?: string | '';
    registType?: RegistType | '';
  }) {
    if (this.lessons.length === 0) throw new Error('수업을 찾을 수 없습니다.');

    const findLesson = this.lessons.find(
      (v) =>
        (code.length > 0 ? v.code === code : true) &&
        (periodText && periodText.length
          ? v instanceof SimulationLesson && v.periodText === periodText
          : true) &&
        (registType && registType.length
          ? v instanceof SimulationLesson && v.registType === registType
          : true),
    );
    if (!findLesson) return this.lessons[0];
    return findLesson;
  }

  /**
   * 데이터만을 가져오고 싶을 때 사용.
   * state 관리나 code splitting 등의 목적이면 useQuery를 따로 선언해서 사용
   */
  public async getAcademyGeoJson() {
    const queryKey = [QUERY_KEY.ACADEMY_GEOJSON, this.id];

    return await getDataWithReactQuery({
      queryKey,
      queryFn: getAcademyGeoJson,
      queryClient: this._queryClient,
    });
  }

  public async getAcademyGeoJsons() {
    const queryKey = [QUERY_KEY.ACADEMY_GEOJSONS, this.id];

    return await getDataWithReactQuery({
      queryKey,
      queryFn: getAcademyGeoJson,
      queryClient: this._queryClient,
    });
  }

  public get() {
    return super.get(['baseAcademy']);
  }

  public isSpecificAcademy(
    academyName:
      | '청룡'
      | '양재'
      | '창동'
      | '모란'
      | '제주'
      | '대진'
      | '동송'
      | '하예서부'
      | '설악산'
      | '강릉'
      | '원영'
      | '현대',
  ) {
    if (academyName === '청룡') {
      return this.id === 'rZwEF7XiAC7pzSiUFk5z';
    }
    if (academyName === '양재') {
      return this.id === 'ciaUcB5L4ziqHFcHfJbE';
    }
    if (academyName === '창동') {
      return this.id === 'aL1qG0sqv4OmscS55Xpz';
    }
    if (academyName === '모란') {
      return this.id === 'Pi8o2tGE3v819jOjfwoD';
    }
    if (academyName === '제주') {
      return this.id === '166gqvaZPw6dSzvPcnvS';
    }
    if (academyName === '대진') {
      return this.id === 'M3iAmzacROKlcOaVAsk5';
    }
    if (academyName === '동송') {
      return this.id === 'WD3cKGbZkNJAdjRpaMHr';
    }
    if (academyName === '하예서부') {
      return this.id === 'z5NDkv4bqsOVF5i0KEHy';
    }
    if (academyName === '설악산') {
      return this.id === 'VioGYtKvrhBYVE0idQYc';
    }
    if (academyName === '강릉') {
      return this.id === 'kn8KqjFl7CoYJccHbHVV';
    }
    if (academyName === '원영') {
      return this.id === 'ZJawK6iLTTE4mYm4ndy9';
    }
    if (academyName === '현대') {
      return this.id === 'D2sqPHdqrmuerQurjyWx';
    }

    return false;
  }
}
