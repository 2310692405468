export function cutByBytes(str: string, bytes: number) {
  let totalBytes = 0;

  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i);
    if (charCode <= 0x7f) {
      totalBytes += 1;
    } else if (charCode <= 0x7ff) {
      totalBytes += 2;
    } else if (charCode <= 0xffff) {
      totalBytes += 3;
    } else {
      totalBytes += 4;
    }

    if (totalBytes > bytes) {
      return str.slice(0, i);
    }
  }

  return str;
}
