import type { FC, SVGProps } from 'react';

import { cn } from 'tailwind-config';

import * as IconSVGs from '../../../assets/icon';

type StaticIconName = keyof typeof IconSVGs;

interface StaticIconProps extends Omit<SVGProps<SVGSVGElement>, 'fill'> {
  icon: StaticIconName;
  size?: number;
}

export const StaticIcon: FC<StaticIconProps> = ({ icon, className, size, ...props }) => {
  const IconSVG = IconSVGs[icon] as unknown as FC<SVGProps<SVGSVGElement>>;

  return (
    <div className="leading-[0px]">
      <IconSVG
        className={cn('inline-block flex-shrink-0', className)}
        style={
          size
            ? {
                width: size,
                height: size,
              }
            : undefined
        }
        {...props}
      />
    </div>
  );
};
