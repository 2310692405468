export const mid = 'society03m';
export const merchantKey =
  'isAfYcARgKxEpcCBDsHodxC5tWLfj+aT/ZQYl8MsyvoCN8o2Ta8w2aN4Q2ljBgKT6b0dG3zHDU2xpNzEenZqPg==';

export const keyInMid = 'society04m';
export const keyInMerchantKey =
  'TLgxhrAi1UWyYEqFrvlvOA8+2M2VeQdOs2cRtNpc8oUxNPT1Y7fycKvEs0G3ogxvxIud1nDQ8v8HVIJaHEByKA==';

export const bid = 'society02mcb012312132033157733';
export const billMid = 'society02m';
export const billMerchantKey =
  'O2PKUvJlIICZSP2sNbF38ik16KGpKAxzAgJOY6WG2iiSohQ653G4Vgx+npcdhXlwFF/3sy3SXgMYiqxeNpLmMQ==';
