export const setURLState = (
  currentURL: string,
  newStates: Record<string, string>[],
  action: 'set' | 'delete',
) => {
  const searchParams = new URLSearchParams(currentURL);

  for (const newState of newStates) {
    Object.entries(newState).forEach(([key, value]) => {
      if (action === 'delete') {
        searchParams.delete(key);
        return;
      }
      searchParams.set(key, value);
    });
  }

  return searchParams.toString();
};
