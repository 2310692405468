import { isDate } from 'lodash-es';

import { BaseModel, SelectedRange, TimeWithDate } from '../../../base-models';

export class VisitTrainingLesson extends TimeWithDate {
  state: VisitTrainingReceiptData['lessons'][number]['state'];
  requestTime?: TimeWithDate | null;

  constructor({ state, requestTime, ...rest }: VisitTrainingReceiptData['lessons'][number]) {
    super(rest);

    requestTime?.date.setHours(0, 0, 0, 0);

    this.state = state;
    this.requestTime = requestTime ? new TimeWithDate(requestTime) : null;
  }
}

export class VisitTrainingReceipt extends BaseModel {
  location: VisitTrainingReceiptData['location'];
  dateRange: SelectedRange | null;
  preferredSchedule: string;
  preferredTimeSlot: string;
  preferredSkill: string[];
  preferredCourse: string[];
  instructorName: string;
  instructorCareer: string;
  instructorIntroduce: string;
  lessons: VisitTrainingLesson[];
  firstLessonType: VisitTrainingReceiptData['firstLessonType'];

  constructor({
    location,
    dateRange,
    preferredSchedule,
    preferredTimeSlot,
    preferredSkill,
    preferredCourse,
    instructorName,
    instructorCareer,
    instructorIntroduce,
    lessons,
    firstLessonType,
  }: VisitTrainingReceiptData) {
    super('VisitTrainingReceipt');

    if (dateRange?.startDate && isDate(dateRange.startDate)) {
      dateRange?.startDate.setHours(0, 0, 0, 0);
    }

    if (dateRange?.endDate && isDate(dateRange.endDate)) {
      dateRange?.endDate.setHours(0, 0, 0, 0);
    }

    this.location = location;
    this.dateRange = dateRange ? new SelectedRange(dateRange) : null;
    this.preferredSchedule = preferredSchedule;
    this.preferredTimeSlot = preferredTimeSlot;

    this.preferredSkill = preferredSkill;
    this.preferredCourse = preferredCourse;
    this.instructorName = instructorName;
    this.instructorCareer = instructorCareer;
    this.instructorIntroduce = instructorIntroduce;
    this.lessons = (lessons ?? []).map((lesson) => new VisitTrainingLesson(lesson));
    this.firstLessonType = firstLessonType;
  }

  get preferredScheduleAndTime() {
    if (this.preferredSchedule === '상관없음' && this.preferredTimeSlot === '상관없음') {
      return '상관없음';
    }
    if (this.preferredSchedule === '상관없음') {
      return this.preferredTimeSlot;
    }
    if (this.preferredTimeSlot === '상관없음') {
      return this.preferredSchedule;
    }
    return `${this.preferredSchedule}/${this.preferredTimeSlot}`;
  }
}
